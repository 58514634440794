.Calculator {
  width: 100%;
  height: 400px;
}

.dcaHeader {
  margin-bottom: 20%;
}

.label {
  color: grey;
}

.portfolioHeader {
  font-size: 17.5px;
}

.dollarLabel {
  width: 15%;
  padding: 6px;
  display: inline-block;
  text-align: center;
  background-color: #171717;
  border-radius: 10px 0 0 10px;
}

.dcaInput {
  width: 85%;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #171717;
  color: lightgrey;
  border: none;
  border-radius: 0 10px 10px 0;
  outline: none;
}

.dcaInput[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background-color: #171717;
  border-radius: 10px;
  overflow: hidden;
}

.dcaInput[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 20px;
}

.dcaInput[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: grey;
  border-radius: 50%;
  box-shadow: -210px 0 0 200px #ff7300;
  cursor: pointer;
  height: 20px;
  width: 20px;
  border: 0;
}

.calculateButton {
  padding: 5px;
  width: 125px;
  background-color: #ff7300;
  color: #171717;
  border: none;
  border-radius: 5px;
}

.calculateButton:focus {
  outline: none;
  box-shadow: none;
}
