.is-boxed {
    background: color-bg(body-outer);

	.body-wrap {
		max-width: $container--width-boxed;
	    margin: 0 auto;
	    box-shadow: color-bg(body-shadow);

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: color-bg(body);
			z-index: -10;
		}
	}
}

.body-wrap {
	position: relative;
    overflow: hidden;
    // Sticky footer
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.site-content {
    flex: 1 0 auto;
}

.icon-bar-desktop {
	position: fixed;
	top: 60%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	margin-left: 1%;
}

.icon-bar-desktop a {
	display: block;
	text-align: center;
	padding: 16px;
	transition: all 0.3s ease;
	color: white;
	font-size: 20px;
  }

.icon-bar-desktop a:hover {
	opacity: 60%
}