.site-header {
  position: absolute !important;
  top: 0;
  width: 100%;
  z-index: 10 !important;
  background: color-bg(header);

  + .site-content {
    .section:first-of-type {
      padding-top: $header-height__mobile;
    }
  }

  .brand {
    margin-right: $header-nav--padding-h;
  }
}

.site-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header-height__mobile;
}

.header-nav {
  flex-grow: 1;

  .header-nav-inner {
    display: flex;
    flex-grow: 1;
  }

  ul {
    display: flex;
    align-items: center;
    flex-grow: 1;
    white-space: nowrap;
    margin-bottom: 0;

    &:first-of-type {
      flex-wrap: wrap;
    }
  }

  li {
    + .header-button {
      margin-left: $header-nav--padding-h;
    }
  }

  a:not(.button) {
    display: block;
    @include anchor-aspect(header);
    @include font-weight(header-link);
    text-transform: $link-header--transform;
    padding: 0 $header-nav--padding-h;

    .invert-color & {
      @include anchor-aspect(header, inverse);
    }
  }

  a.button {
    margin-left: $header-nav--padding-h;
  }
}

.header-nav-center:first-of-type {
  flex-grow: 1;
  justify-content: flex-end;
}

.header-nav-left {
  justify-content: flex-start;

  + .header-nav-left {
    flex-grow: 0;
  }
}

.header-nav-right {
  justify-content: flex-end;

  + .header-nav-right {
    flex-grow: 0;
  }
}

.header-nav-toggle {
  display: none;
}

@include media("<=medium") {
  .header-nav-toggle {
    display: block;

    // Header navigation when the hamburger is a previous sibling
    + .header-nav {
      flex-direction: column;
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      z-index: 9999;
      background: color-bg(menu-mobile);
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: max-height 0.25s ease-in-out, opacity 0.15s;

      &.is-active {
        opacity: 1;
      }

      .header-nav-inner {
        flex-direction: column;
        padding: $header-nav--padding-v__mobile;
      }

      ul {
        display: block;
        text-align: center;

        a:not(.button) {
          display: inline-flex;
          @include anchor-aspect(header-mobile);
          padding-top: $header-nav--padding-v__mobile / 2;
          padding-bottom: $header-nav--padding-v__mobile / 2;
        }
      }

      a.button {
        margin-left: 0;
        margin-top: $header-nav--padding-v__mobile / 2;
        margin-bottom: $header-nav--padding-v__mobile / 2;
      }
    }
  }
}

@include media(">medium") {
  .site-header {
    + .site-content {
      .section:first-of-type {
        padding-top: $header-height__desktop;
      }
    }
  }

  .site-header-inner {
    height: $header-height__desktop;
  }
}

.tickerContent {
  font-size: 15px;
  margin: auto 10px;
  display: inline-block;
}
@media (max-width: 992px) {
  .service {
    width: 100%;
    display: block;
  }
  .serviceSummary {
    width: 90%;
  }
}
.sloganContainer {
  position: relative;
  top: 40%;
  left: 5%;
  width: 30%;
}
.carousel .slide {
  background: none;
}
.slogan {
  color: #fff;
  text-align: left;
  letter-spacing: 2px;
}
.homeButton {
  cursor: pointer;
  color: #ff7300;
  text-align: left;
  font-size: 2.5vh;
}
.homeButton:hover {
  color: #ff7300;
  -webkit-text-decoration-color: #ff7300;
  text-decoration-color: #ff7300;
}
.rowContainer {
  padding: 100px 0;
  text-align: center;
}
.headerCentered {
  text-align: center;
  margin-bottom: 50px;
}
.missionStatementContainer {
  margin: auto;
  padding: 20px;
  width: 60%;
}
.missionStatementContainer p {
  font-size: 20px;
  letter-spacing: 1px;
}
.gif {
  width: 400px;
  height: 200px;
}
@media (max-width: 992px) {
  .sloganContainer {
    width: 50%;
    top: 5%;
  }
  .slogan {
    font-size: 22.5px;
  }
  .rowContainer {
    padding: 50px 0;
    text-align: center;
  }
  .missionStatementContainer {
    width: 90%;
  }
  .gif {
    width: 100%;
    height: 100%;
  }
}
.Calculator {
  width: 100%;
  height: 400px;
}
.dcaHeader {
  margin-bottom: 20px;
}
.label {
  color: grey;
}
.portfolioHeader {
  font-size: 17.5px;
}
.dollarLabel {
  width: 15%;
  display: inline-block;
  text-align: center;
  border-radius: 10px 0 0 10px;
}
.dcaInput,
.dollarLabel {
  padding: 10px;
  background-color: #171717;
}
.dcaInput {
  width: 85%;
  margin-bottom: 20px;
  color: #d3d3d3;
  border: none;
  border-radius: 0 10px 10px 0;
  outline: none;
}
.dcaInput[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background-color: #171717;
  border-radius: 10px;
  overflow: hidden;
}
.dcaInput[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 20px;
}
.dcaInput[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: grey;
  border-radius: 50%;
  box-shadow: -210px 0 0 200px #ff7300;
  cursor: pointer;
  height: 20px;
  width: 20px;
  border: 0;
}
.calculateButton {
  padding: 5px;
  width: 125px;
  background-color: #ff7300;
  color: #171717;
  border: none;
  border-radius: 5px;
}
.calculateButton:focus {
  outline: none;
  box-shadow: none;
}
.HistoricChart {
  width: 100%;
  height: 500px;
}
.seriesLabel {
  padding: 1vw;
  display: inline;
  cursor: pointer;
}
.spinnerContainer {
  width: 100%;
  height: 400px;
}
.spinner {
  position: relative;
  top: 50%;
  left: 50%;
}
@media (max-width: 992px) {
  .HistoricChart,
  .spinnerContainer {
    height: 300px;
  }
}
.opportunityCostStatementContainer {
  width: 80%;
  margin: auto;
  padding: 20px;
  font-size: 18px;
  letter-spacing: 1px;
}
.opportunityContainer {
  margin: 100px auto;
  width: 90%;
}
.calculator {
  width: 22.5%;
  float: right;
}
.historicChart {
  width: 75%;
  float: left;
  margin-top: 50px;
  margin-bottom: 50px;
}
@media (max-width: 992px) {
  .calculator,
  .historicChart {
    width: 100%;
  }
}
.timestamp {
  color: #8e8e8e;
}
.blogContainer {
  margin: 50px auto;
  overflow: hidden;
}
.addPost {
  background-color: #171717;
  color: #d3d3d3;
  border: none;
}
.passwordInput {
  padding: 10px;
  border: none;
  border-radius: 10px;
  outline: none;
  background-color: #171717;
  color: #d3d3d3;
}
.articleContainer {
  margin-bottom: 50px;
}
.articleInput {
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
  display: block;
  background-color: #171717;
  color: #d3d3d3;
  border: none;
  border-radius: 10px;
  outline: none;
}
.contactContainer {
  margin: 50px auto;
  width: 90%;
  overflow: hidden;
}
.contactInfo {
  margin: 50px 0;
}
.input {
  border: none;
}
.input,
.input:focus {
  background-color: #171717;
  color: #d3d3d3;
}
