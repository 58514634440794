.historicChart {
  width: 100%;
  height: 500px;
  margin-top: 100;
}

.seriesLabel {
  padding: 1vw;
  display: inline;
  cursor: pointer;
}

.spinnerContainer {
  width: 100%;
  height: 400px;
}

.timestamp {
  color: #8e8e8e;
}

.spinner {
  position: relative;
  top: 50%;
  left: 50%;
}

@media (max-width: 992px) {
  .HistoricChart {
    height: 300px;
  }

  .spinnerContainer {
    height: 300px;
  }
}
.tickerContent {
  font-size: 15px;
  margin: auto 10px;
  display: inline-block;
}
