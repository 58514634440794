.c-app-footer {
    border-top: 1px solid #ccc;
    font-size: .9rem
}

.c-app-footer,
.c-app-footer .c-app-footer__inner {
    display: flex;
    flex-direction: column;
    align-items: center
}

.c-app-footer .c-app-footer__inner {
    padding: 18px;
    max-width: 1280px;
    margin: 0 auto;
    color: #fff;
    text-align: center
}

.c-app-footer .c-app-footer__inner a {
    color: #fff;
    font-weight: 500;
    margin: 12px 18px
}

.c-app-footer .c-app-footer__inner span {
    float: right;
    margin: 30px 18px 12px;
    color: #ccc
}

@media (min-width:768px) {
    .c-app-footer .c-app-footer__inner {
        flex-direction: row
    }

    .c-app-footer .c-app-footer__inner a,
    .c-app-footer .c-app-footer__inner span {
        margin: 0 18px
    }
}

.c-public-footer {
    margin-top: 100px;
    font-family: ubuntu;
    border-top: 1px solid #444;
    background-color: #080a15
}

.c-public-footer__inner {
    display: grid;
    padding: 48px 30px;
    max-width: 1500px;
    margin: 0 auto;
    width: 100%;
    grid-gap: 30px;
    color: #fff;
    white-space: normal;
    overflow: visible;
    grid-template-areas: "footer-left footer-grid-wrapper";
    grid-template-columns: 2fr 3fr
}

@media (min-width:544px) {
    .c-public-footer__inner {
        padding: 78px
    }
}

@media (max-width:1280px) {
    .c-public-footer__inner {
        grid-template-areas: "footer-left""footer-grid-wrapper";
        grid-template-columns: 1fr
    }
}

.c-public-footer__column h4 {
    margin-bottom: .5rem;
    font-size: 16px
}

.c-public-footer__column a {
    font-weight: 400;
    color: #ccc;
    line-height: 1.7;
    font-size: 14px
}

.c-public-footer__left {
    grid-area: footer-left;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    align-items: flex-start
}

@media (max-width:1280px) {
    .c-public-footer__left {
        flex-direction: row;
        justify-content: space-between
    }
}

.c-public-footer__logo img {
    max-width: 300px
}

.c-public-footer__copyright {
    margin-bottom: 30px
}

@media (max-width:1280px) {
    .c-public-footer__copyright {
        margin-bottom: 0
    }
}

.c-public-footer__copyright p {
    font-size: 14px;
    white-space: normal
}

@media (max-width:1280px) {
    .c-public-footer__app {
        /* margin-top: auto;
        margin-bottom: auto; */
    }
}

.c-public-footer__app img {
    width: 35%;
    display: inline-block;
}

.c-public-footer__play img {
    width: 40%;
    margin-left: -15px;
}

.c-public-footer__app-icon:hover {
    opacity: 70%
}

.c-public-footer__grid-wrapper {
    grid-area: footer-grid-wrapper;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start
}

.c-public-footer__grid-item {
    flex-grow: 1;
    width: 20%;
    margin: 0 40px 40px 0
}

@media (max-width:768px) {
    .c-public-footer__grid-item {
        width: 40%
    }

    .c-public-footer__grid-item:nth-child(2n) {
        margin-right: 0
    }
}

.c-public-footer__grid-item:last-child,
.c-public-footer__grid-item:nth-child(4) {
    margin-right: 0
}

.c-public-footer__grid-item-title {
    border-bottom: 1px solid #4b5066;
    white-space: normal
}

.c-public-footer__grid-item-list {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    line-height: 1.2rem;
    white-space: normal
}

.c-public-footer__social-icon {
    height: 17px;
    display: inline-block;
    vertical-align: center;
    padding-right: 10px;
    filter: invert(100%);
}

.c-public-footer__header {
    font-family: ubuntu;
    color: #FF7300;
    font-size: 16px;
}

.c-knowledge-base__article-top img,
.c-knowledge-base__article-widget img,
.c-knowledge-base__article-widgets-top img {
    height: 120px
}

.c-knowledge-base__hero {
    text-align: center;
    width: 100%;
    padding: 8rem 48px 78px
}

.c-link {
    user-select: none;
    color: #FF7300;
    font-weight: 500;
    text-decoration: none;
    transition: opacity .15s linear;
    cursor: pointer
}

.c-blog__post-inner a:hover,
.c-breadcrumb__link:hover,
.c-knowledge-base__article-body a:hover,
.c-link:hover {
    color: #FFF
}

.c-link--with-underline {
    border-bottom: 1px solid #c5a063
}

.c-link--no-color {
    color: inherit !important
}

.c-knowledge-base__hero {
    padding-top: 48px
}

.c-knowledge-base {
    background-color: #f6f7fa;
    padding: 30px 18px;
    flex: 1
}

@media (min-width:768px) {
    .c-knowledge-base {
        padding: 48px 30px
    }
}

.c-knowledge-base__inner {
    max-width: 1012px;
    margin: 0 auto;
    color: #4b5066
}

.c-knowledge-base__inner--grid {
    display: grid;
    grid-gap: 12px
}

@media (min-width:544px) {
    .c-knowledge-base__inner--grid {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
    }
}

.c-bitcoin-price__footer {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    margin-top: 120px
}

.c-bitcoin-price__footer--left {
    margin-top: 0;
    padding: 0 30px
}

@media (min-width:544px) {
    .c-bitcoin-price__footer--left {
        margin-top: 40px
    }

    .c-bitcoin-price__footer--left p {
        margin: 0 40px
    }
}


@font-face {
    font-family: ubuntu;
    font-display: fallback;
    src: url(../../assets/fonts/ubuntu/Ubuntu-M.ttf) format("woff2");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: ubuntu;
    font-display: fallback;
    src: url(../../assets/fonts/ubuntu/Ubuntu-M.ttf) format("woff2");
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: ubuntu;
    font-display: fallback;
    src: url(../../assets/fonts/ubuntu/Ubuntu-M.ttf) format("woff2");
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: ubuntu;
    font-display: fallback;
    src: url(../../assets/fonts/ubuntu/Ubuntu-M.ttf) format("woff2");
    font-weight: 900;
    font-style: normal
}